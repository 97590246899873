<template>
  <div class="flex fixed bottom-0 py-4 m-0 w-full bg-blue-light" style="z-index: 1001">
    <Button url="/dashboard" kind="link" class="place-content-center place-items-center m-auto">
      <Icon icon="campaign" width="24" height="24" />
    </Button>

    <Button
      v-if="checkIfOrg"
      :url="adminOrganization ? '/admin' : '/streamers'"
      kind="link"
      class="place-content-center place-items-center m-auto"
    >
      <Icon icon="headset" width="24" height="24" />
    </Button>

    <Button v-if="walletEnabled" url="/wallet" kind="link" class="place-content-center place-items-center m-auto">
      <Icon icon="wallet" width="24" height="24" />
    </Button>

    <Button
      v-if="streamerProfile"
      :url="`/profile/${getUserInfo.username}`"
      kind="link"
      class="place-content-center place-items-center m-auto"
    >
      <Icon icon="avatar" width="24" height="24" />
    </Button>

    <Button v-if="streamerProfile" url="/settings" kind="link" class="place-content-center place-items-center m-auto">
      <Icon icon="settings" width="24" height="24" />
    </Button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Icon, Button } from "cavea-design-system";

export default {
  name: "BottomNav",

  components: {
    Icon,
    Button,
  },

  computed: {
    ...mapGetters(["getUserInfo", "getOrganization"]),

    streamerProfile() {
      if (this.getUserInfo.type !== "streamer") return null;

      return `/profile/${this.getUserInfo.username}`;
    },

    walletEnabled() {
      if (this.getUserInfo?.type === "streamer" || this.getOrganization?.settings?.products?.netwrk_teams) {
        return true;
      }

      return false;
    },

    checkIfOrg() {
      return this.getUserInfo.type === "admin" || this.getUserInfo.type === "user";
    },

    checkIfAdmin() {
      return this.getUserInfo.type === "admin";
    },

    adminOrganization() {
      return this.getOrganization?._id === "6104316858d6180c099272b9";
    },
  },
};
</script>
